import { RouteRecordRaw } from 'vue-router';
import routesConfig from './routesConfig';
import CourseService from 'src/services/CourseService';
import { useGlobalStore } from 'src/stores/global-store';
import { useUserInfoStore } from 'src/stores/user-info-store';
import { useAuth } from 'src/composables/auth/useAuth';
import { inject } from 'vue';
import { UserInfoStore } from 'src/types';

const routes: RouteRecordRaw[] = [

  {
    path: '/',
    redirect: (to) => {
      const userInfoStore = useUserInfoStore();
      if (userInfoStore.isAdmin) {
        return { path: routesConfig.adminCoursesDashboard };
      } else if (userInfoStore.type === 'student') {
        return { path: routesConfig.profile };
      } else if (userInfoStore.type === 'teacher') {
        return { path: routesConfig.course };
      } else {
        return { path: routesConfig.signin };
      }
    },
  },
 /* {
    path: '/',
    component: () => import('layouts/FullSizeLayout.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: routesConfig.profile,
        component: () => import('pages/StudentHomePage.vue'),
      },
      {
        path: routesConfig.diningRoom,
        component: () => import('pages/PetDiningRoomPage.vue'),
      },
    ],
  },*/
  {
    path: routesConfig.profile,
    component: () => import('pages/StudentHomePage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.diningRoom,
    component: () => import('pages/PetDiningRoomPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/',
    component: () => import('layouts/FullSizeLayout.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: routesConfig.viewClassStudent,
        component: () => import('pages/ViewClassStudentPage.vue'),
      },
    ],
  },
  {
    path: routesConfig.meeting,
    component: () => import('pages/MeetingPage.vue'),
    meta: { layout: 'no-layout', requiresAuth: true },
  },
  {
    path: routesConfig.bbbRedirect,
    component: () => import('pages/RedirectPage.vue'),
    meta: { layout: 'no-layout' },
  },
  {
    path: routesConfig.signin,
    name: 'signin',
    component: () => import('pages/auth/LoginPage.vue'),
    meta: { layout: 'no-layout', redirectIfAuth: true },
  },

  {
    path: routesConfig.verifyEmailMessage,
    component: () => import('pages/auth/EmailVerificationMessage.vue'),
    meta: { layout: 'no-layout' },
  },
  {
    path: routesConfig.enrolmentForm,
    component: () => import('pages/auth/EnrolmentPage.vue'),
    name: 'enrolment',
    meta: { layout: 'no-layout', requiresAuth: true },
  },
  {
    path: routesConfig.signup,
    name: 'signup',
    component: () => import('pages/auth/RegisterPage.vue'),
    meta: { layout: 'no-layout', redirectIfAuth: true },
  },

  {
    path: routesConfig.resetPassword,
    name: 'resetPassword',
    component: () => import('pages/auth/ResetPasswordPage.vue'),
    meta: { layout: 'no-layout', redirectIfAuth: true },
  },

  {
    path: routesConfig.resetPasswordVerify,
    name: 'resetPasswordVerify',
    component: () => import('pages/auth/ResetPasswordVerifyPage.vue'),
    meta: { layout: 'no-layout' },
  },

  {
    path: routesConfig.emailVerification,
    name: 'emailVerification',
    component: () => import('pages/auth/EmailVerificationPage.vue'),
    meta: { layout: 'no-layout' },
  },
  {
    path: routesConfig.petSelection,
    component: () => import('pages/PetSelectionPage.vue'),
    name: 'petSelection',
    meta: { layout: 'no-layout', requiresAuth: true },
  },
  {
    path: routesConfig.termsAndConditions,
    name: 'termsAndConditions',
    component: () => import('pages/auth/TermsAndConditionsPage.vue'),
    meta: { layout: 'no-layout' },
  },
  {
    path: routesConfig.adminCoursesDashboard,
    component: () => import('pages/admin/CoursesPage.vue'),
    beforeEnter: async (to, from, next) => {
      const userInfoStore = useUserInfoStore();
      if (!userInfoStore.isAdmin) {
        next({ path: '/404' });
        return;
      }
      next();
    },
  },
  {
    path: routesConfig.adminAddCourse,
    component: () => import('pages/admin/AddCoursePage.vue'),
    beforeEnter: async (to, from, next) => {
      const userInfoStore = useUserInfoStore();
      if (!userInfoStore.isAdmin) {
        next({ path: '/404' });
        return;
      }
      next();
    },
  },
  {
    path: routesConfig.adminCourseDetails,
    component: () => import('pages/admin/CourseDetailsPage.vue'),
    beforeEnter: async (to, from, next) => {
      const userInfoStore = useUserInfoStore();
      if (!userInfoStore.isAdmin) {
        next({ path: '/404' });
        return;
      }
      next();
    },
  },
  {
    path: routesConfig.adminEditCourse,
    name: 'adminEditCourse',
    component: () => import('pages/admin/EditCoursePage.vue'),
    beforeEnter: async (to, from, next) => {
      const userInfoStore = useUserInfoStore();
      if (!userInfoStore.isAdmin) {
        next({ path: '/404' });
        return;
      }
      next();
    },
  },
  {
    path: routesConfig.adminStudentsDashboard,
    component: () => import('pages/admin/StudentsPage.vue'),
    beforeEnter: async (to, from, next) => {
      const userInfoStore = useUserInfoStore();
      if (!userInfoStore.isAdmin) {
        next({ path: '/404' });
        return;
      }
      next();
    },
  },

  {
    path: routesConfig.adminStudentProfile,
    component: () => import('pages/admin/StudentProfile.vue'),
    beforeEnter: async (to, from, next) => {
      const userInfoStore = useUserInfoStore();
      if (!userInfoStore.isAdmin) {
        next({ path: '/404' });
        return;
      }
      next();
    },
  },

  {
    path: routesConfig.adminTeachersDashboard,
    component: () => import('pages/admin/TeachersPage.vue'),
    beforeEnter: async (to, from, next) => {
      const userInfoStore = useUserInfoStore();
      if (!userInfoStore.isAdmin) {
        next({ path: '/404' });
        return;
      }
      next();
    },
  },

  {
    path: routesConfig.adminTeacherProfile,
    component: () => import('pages/admin/TeacherProfile.vue'),
    beforeEnter: async (to, from, next) => {
      const userInfoStore = useUserInfoStore();
      if (!userInfoStore.isAdmin) {
        next({ path: '/404' });
        return;
      }
      next();
    }
  },
  {
    path: routesConfig.adminLessonHistory,
    component: () => import('pages/admin/LessonHistoryPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.adminLessonDetails,
    component: () => import('pages/admin/LessonDetailsPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.myCalendar,
    component: () => import('pages/admin/CalendarPageWrapper.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.account,
    component: () => import('pages/admin/AccountProfilePage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.accountPolicy,
    component: () => import('pages/admin/AccountPolicyPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.accountEdit,
    component: () => import('pages/admin/AccountProfilePageEdit.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.accountUsage,
    component: () => import('pages/admin/AccountUsagePage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/404',
    component: () => import('pages/ErrorNotFound.vue'),
  },
  {
    path: routesConfig.profileDetails,
    component: () => import('pages/StudentClassDetailsPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.course,
    component: () => import('pages/CoursePage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.quiz,
    component: () => import('pages/QuizFoldersPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.folder,
    component: () => import('pages/FolderPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.quizEdit,
    component: () => import('pages/QuizEditPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.viewClass,
    component: () => import('pages/ViewClassPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.lessonHistory,
    component: () => import('pages/ViewClassLessonHistory.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.lessonDetails,
    component: () => import('pages/ViewLessonDetails.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.lessonDetailsReview,
    component: () => import('pages/ViewLessonReview.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.lessonDetailsRecording,
    component: () => import('pages/ViewLessonRecording.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.lessonDetailsTeacherFeedback,
    component: () => import('pages/ViewLessonFeedbackWrapper.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.courseMaterial,
    component: () => import('pages/ViewClassCourseMaterialPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.calendar,
    component: () => import('pages/CalendarView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.homework,
    component: () => import('pages/HomeworkDashboardWrapper.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.homeworkAssign,
    component: () => import('pages/HomeworkAssignPage.vue'),
    beforeEnter: async (to, from, next) => {
      const userInfoStore = useUserInfoStore();
      if (userInfoStore.type !== 'teacher') {
        next({ path: '/404' });
        return;
      }
      next();
    },
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.homeworkDetails,
    component: () => import('pages/HomeworkDetailsWrapper.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.homeworkEdit,
    component: () => import('pages/HomeworkEditPage.vue'),
    beforeEnter: async (to, from, next) => {
      const userInfoStore = useUserInfoStore();
      if (userInfoStore.type !== 'teacher') {
        next({ path: '/404' });
        return;
      }
      next();
    },
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.submissionDetails,
    component: () => import('pages/SubmissionDetailsPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: routesConfig.homeworkEditSubmission,
    component: () => import('pages/HomeworkEditSubmissionPage.vue'),
    beforeEnter: async (to, from, next) => {
      const userInfoStore = useUserInfoStore();
      if (userInfoStore.type !== 'student') {
        next({ path: '/404' });
        return;
      }
      next();
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/:catchAll(.*)*',
    redirect: '/404',
  },
];

export default routes;
