import posthog, {CapturedNetworkRequest} from 'posthog-js'

export function usePostHog() {
  const VITE_POSTHOG_API_KEY = process.env.VITE_POSTHOG_API_KEY || '';
  const VITE_POSTHOG_HOST =  process.env.VITE_POSTHOG_HOST || 'https://eu.i.posthog.com/';
  if (process.env.VITE_ENV === 'prod' || process.env.VITE_ENV === 'prod-china') {
    posthog.init(VITE_POSTHOG_API_KEY, {
      api_host: process.env.VITE_POSTHOG_HOST,
      autocapture:
        // true,
        // false,
        {
        dom_event_allowlist: ['click'],
        element_allowlist: ['button'],
      },
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
            password: true, 
        }
      }
    })
  
    return {
      posthog
    }
  }
  return null; 
}
