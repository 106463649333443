import { boot } from 'quasar/wrappers';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { useAuth } from 'src/composables/auth/useAuth';
import { useUserInfoStore } from 'src/stores/user-info-store';
import { globalRouter } from 'src/router/globalRouter';
import { useMeetingStore } from 'src/stores/meeting-store';
import MeetingService from 'src/services/MeetingService';
import { useAuthStore } from 'src/stores/auth-store';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
    $api: AxiosInstance;
    $s3Axios: AxiosInstance;
  }
}
interface RequestHeaders {
  [key: string]: string;
}

// Define the structure of a retry queue item
interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

const baseURL = '/api/'; // Default baseURL for Vercel or global environment

// Check if the current domain is for China version
/*
if (window.location.hostname === 'funclass.com.cn') {
  baseURL = 'https://backend.funclass.com.cn/api/';
};*/

const api = axios.create({ baseURL: baseURL });

let isRefreshing = false;
let refreshTokenPromise: Promise<string | null> | null = null;
const retryQueue: RetryQueueItem[] = [];

api.interceptors.request.use(
  (config) => {
    const c: AxiosRequestConfig = config;
    const { accessToken } = useAuth();
    const headers = c.headers as RequestHeaders;
    
    if (accessToken.value) {
      
      headers.Authorization = `Bearer ${accessToken.value}`;
    }

    return c;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => response,
  async (error: AxiosError) => {
    const { config, response } = error;
    const originalRequest = config;
    const router =  globalRouter.router;
    await router.isReady();
    const currentRoute = globalRouter.router?.currentRoute.value;
    const currentRouteName = currentRoute?.name;

     // Check for specific 401 error with the message indicating invalid refresh token
     if (response && /*response.status === 401 && response.data?.detail === "No valid token found in cookie 'refresh_token'" &&*/ response.data?.code === "token_not_valid" 
     && currentRouteName !== 'signup' && currentRouteName !== 'signin' && currentRouteName !== 'resetPassword'  && currentRouteName !== 'resetPasswordVerify' && currentRouteName !== 'emailVerification' && currentRouteName !== 'termsAndConditions') {
      console.log('currentRouteName',currentRouteName)
      console.log('No valid refresh token found, redirecting to /signin');
      const authStore = useAuthStore();
      await authStore.setIsAuthenticatedAsync(false);
      const { clearUserInfo } = useAuth();
      clearUserInfo();
      globalRouter.router?.push('/signin');
      return Promise.reject(error);
  }
    // Check if the error is a 401 or 403 and handle token refresh
    if ((response && (response.status === 401 || response.status === 403)) &&
        currentRouteName !== 'signin' &&
        currentRouteName !== 'signup' &&
        currentRouteName !== 'resetPassword' &&
        currentRouteName !== 'resetPasswordVerify' &&
        !originalRequest._retry) {
      
      if (!isRefreshing) {
        isRefreshing = true;
        const { refreshToken, logout, clearUserInfo } = useAuth();
        const userInfoStore = useUserInfoStore();
        try {
          refreshTokenPromise = refreshToken(userInfoStore.id === '' ? true : false);
          const { accessToken } = await refreshTokenPromise;
          if (accessToken) {
            onRefreshed(accessToken);
            originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
            return api(originalRequest);
          } else {
            throw new Error('Failed to refresh token');
          }
        } catch (refreshError) {
          clearUserInfo();
          await logout();
          globalRouter.router?.push('/signin');
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
          refreshTokenPromise = null;
        }
      } else {
        return new Promise((resolve, reject) => {
          subscribeTokenRefresh((token: string | null, error: any) => {
            if (error) {
              reject(error);
            } else if (token) {
              originalRequest.headers['Authorization'] = `Bearer ${token}`;
              resolve(api(originalRequest));
            }
          });
        });
      }
    }

    // Handle 404 error for missing lessonId
    if (response && response.status === 404 && originalRequest.url.includes('/lessons/')) {
      const meetingStore = useMeetingStore();
      const courseId = meetingStore.courseId;
      if (courseId && originalRequest._retryCount < MAX_RETRIES) {
        try {
          const lessonResponse = await MeetingService.checkForExistingLesson(courseId);
          const lessonId = lessonResponse.id;
          if (lessonId) {
            originalRequest.url = originalRequest.url.replace('/lessons//', `/lessons/${lessonId}/`);
            meetingStore.setLessonId(lessonId);
            originalRequest._retryCount += 1;
            return api(originalRequest);
          }
        } catch (lessonError) {
          console.error('Failed to fetch existing lesson:', lessonError);
        }
      }
    }

    return Promise.reject(error);
  }
);

function subscribeTokenRefresh(cb: (token: string | null, error: any) => void) {
  retryQueue.push(cb);
}

function onRefreshed(token: string | null) {
  retryQueue.forEach(cb => cb(token, null));
  retryQueue.length = 0;
}

const MAX_RETRIES = 3;

// Axios instance for S3 uploads
const s3Axios = axios.create();


export default boot(({ app }) => {
  app.config.globalProperties.$axios = axios;
  app.config.globalProperties.$api = api;
  app.config.globalProperties.$s3Axios = s3Axios;
});

export { api, s3Axios };
